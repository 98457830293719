import React, { useState, useEffect } from "react";
import { useStyletron } from "styletron-react";
import { Link } from "gatsby";

import { Grid, Cell } from "baseui/layout-grid";
import { Heading, HeadingLevel } from "baseui/heading";
import { Radio, RadioGroup } from "baseui/radio";
import { Paragraph1 } from "baseui/typography";
import { Button, KIND } from "baseui/button";
import { ChevronRight } from "baseui/icon";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { PROGRAMS } from "../data/programs";
import Calc from "../components/calc";

const Calculator = ({ location }) => {
  const [activeProgramID, setActiveProgramID] = useState(
    location?.state?.id || PROGRAMS.length - 3
  );

  const [css] = useStyletron();
  const space = css({ marginLeft: "20px" });

  const program = PROGRAMS[activeProgramID];
  const selfLink = program?.links?.self;

  const [radioValue, setRadioValue] = useState((program.id + 1).toString());
  const programsRadioBtns = PROGRAMS.map((each) => {
    const { id, name, interestRate, loanTerm, active } = each;
    if (!active) return;
    return (
      <Radio
        key={id.toString()}
        value={(id + 1).toString()}
        description={`${interestRate}%, до ${loanTerm || 24} мес.`}
      >
        {name}
      </Radio>
    );
  });
  return (
    <Layout>
      <SEO
        title="Подобрать программу"
        description="Расчёт процентов и платежей по микрозайму для бизнеса"
      />
      <HeadingLevel>
        <Grid>
          <Cell span={(4, 8, 12)}>
            <Heading>Подобрать программу</Heading>
          </Cell>
        </Grid>
        <Grid>
          <Cell order={1} span={(4, 8, 3)}>
            <HeadingLevel>
              <Heading styleLevel={5}>Программы</Heading>
            </HeadingLevel>
            <RadioGroup
              name="programs"
              value={radioValue}
              onChange={(e) => {
                setRadioValue(e.target.value);
                setActiveProgramID(e.target.value - 1);
              }}
            >
              {programsRadioBtns}
            </RadioGroup>
          </Cell>
          <Cell span={(4, 8, 9)}>
            <HeadingLevel>
              <Heading styleLevel={5}>Программа {program.name}</Heading>
              <Paragraph1>
                Ставка {program.interestRate.toLocaleString("ru-RU")}% годовых
                <br />
                Cумма до{" "}
                {program?.maxAmount?.toLocaleString("ru-RU") ||
                  (5_000_000).toLocaleString("ru-RU")}{" "}
                &#8381;
                <br />
                Срок до {program.loanTerm || 24} месяцев
                <br />
                {program.isGraceDisabled
                  ? "Без льготного периода"
                  : `Льготный период до ${
                      program.maxGracePeriod || (program.loanTerm || 24) - 1
                    } мес.`}
              </Paragraph1>
              <Calc programId={activeProgramID} />
              <div
                className={css({
                  marginTop: "40px",
                  display: "flex",
                  alignContent: "center",
                })}
              >
                <Button $as={Link} to="/apply" kind={KIND.primary}>
                  Подать заявку
                </Button>
                <span className={space} />
                {selfLink && (
                  <Button
                    $as={Link}
                    to={selfLink.href}
                    kind={KIND.secondary}
                    endEnhancer={<ChevronRight size={20} />}
                  >
                    Подробнее о программе
                  </Button>
                )}
              </div>
            </HeadingLevel>
          </Cell>
        </Grid>
      </HeadingLevel>
    </Layout>
  );
};

export default Calculator;
